.App-link {
  color: #61dafb;
}

body {
  background-color: #9f435f;
  width: 100%;
  padding: 0%;
  margin: 0%;
  height: available;
}

html {
  background-color: #1e2761;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
  background: #6cf;
}

p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
