.FooterBar {
  text-align: center;
}

#personal:link {
  color: #59a2d9ff;
  background-color: transparent;
  text-decoration: none;
}

#personal:visited {
  color: #bd7092;
  background-color: transparent;
  text-decoration: none;
}

#personal:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

.sociolcons {
  align-items: center;
}

.footer {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
}
