div.MuiPaper-root {
  transition: none !important;
}

.Typography {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
}
/* Centered text */
.centeredalt {
  position: absolute;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 4rem;
  left: 6vw;
}

.headLink:link {
  color: #ffc043;
  background-color: transparent;
  text-decoration: none;
}

.headLink:visited {
  color: #ffc043;
  background-color: transparent;
  text-decoration: none;
}

.container {
  position: relative;
  text-align: start;
  color: #ffffff;
  margin: 0%;
  display: flex;
}
