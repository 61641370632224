#just-line-break {
  white-space: pre-line;
}

#line-break-and-tab {
  white-space: pre-wrap;
}

.description {
  padding-left: 2%;
  align-self: start;
}

.icon {
  padding-right: 2%;
}
.lockicon {
  padding-right: 0%;
}
