.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 400px;
  margin: 2.8%;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.edutext {
  text-align: center;
  padding-top: 0%;
}

.courseworktext {
  text-align: center;
  padding-top: 3%;
}

.allcourseworktext {
  text-align: center;
  padding-top: 3%;
}
